import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FuseNavigationService } from '../../../@fuse/components/navigation/navigation.service';
import { Observable, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  navigation: string;
  userType: BehaviorSubject<any> = new BehaviorSubject('')
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }
  
  constructor(public http: HttpClient, private _fuseNavigationService: FuseNavigationService, private routerLink: Router) {
    super(http, "usuario");
  }
  user: any;

  public login(login: any) {
    return this.http.post<any>(`${environment.API}/sessions`, JSON.stringify(login), this.httpOptions)
  }

  public getUsuario() {
    return this.http.get(this.actionUrl + "me");
  }

 public logout() {
    localStorage.clear();
    this.routerLink.navigate(["auth/login"]);
 }

  public setToken(token) {
    localStorage.setItem('token', token);
  }


  public getToken() {
    return localStorage.getItem('token');
  }

  public setUser(user) {
    localStorage.setItem('usuario', user.usuario);
    // localStorage.setItem('tipoUsuario', user.tipoUsuario);
    // this.userType.next(user.tipoUsuario);
  }

  public getUserName() {
    return localStorage.getItem('usuario');
  }

  public getTipoUsuario() {
    return localStorage.getItem('tipoUsuario');
  }

  public enviarEmail(email: any) {
    return this.http.put<any>(`${environment.API}/usuario/redefinir-senha`, JSON.stringify(email), this.httpOptions)
  }

}