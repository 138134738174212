import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseNavigationService } from './navigation.service';
import { UserAcessAuth } from '../../../app/helpers/user-access.service';


@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    // Private
    private _unsubscribeAll: Subject<any>;
    private navigation;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private userAcess: UserAcessAuth
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        this.montaMenu();
    }

    montaMenu() {
      let menu = this._fuseNavigationService.getCurrentNavigation();
      let options = [];
      options.push(
                    {
                            id: "receitas",
                            title: "Receitas",
                            type: "item",
                            icon: "assignment",
                            url: "/receitas"
                    },

                    {
                            id: "meus-dados",
                            title: "Meus Dados",
                            type: "item",
                            icon: "assignment_ind",
                            url: "/meus-dados"
                    },
      );
      
      if(localStorage.getItem('administrador') === 'true') {
                options.push(
                    {
                            id: "usuarios",
                            title: "Usuários",
                            type: "item",
                            icon: "person",
                            url: "/usuarios"
                    },
                    {
                            id: "empresa",
                            title: "Empresas",
                            type: "item",
                            icon: "business",
                            url: "/empresas"
                    }
                );
            }
            menu[0].children = options;
    }


}
