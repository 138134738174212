import { NgModule } from '@angular/core';
import { FuseCountdownComponent } from './countdown.component';



@NgModule({
    declarations: [
        FuseCountdownComponent
    ],
    exports: [
        FuseCountdownComponent
    ],
})
export class FuseCountdownModule {
}
