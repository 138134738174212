import { NgModule } from '@angular/core';
import { FuseHighlightComponent } from './highlight.component';



@NgModule({
    declarations: [
        FuseHighlightComponent
    ],
    exports: [
        FuseHighlightComponent
    ],
})
export class FuseHighlightModule {
}
