import { FuseNavigation } from "../../@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "",
        type: "group",
        children: [
            {
                id: "empresa",
                title: "Empresas",
                type: "item",
                icon: "business",
                url: "/empresa"
            }
        ]
    },
    {
        id: "applications",
        title: "Configurações",
        // translate: "NAV.BASIC_ENTRIES",
        type: "group",
        children: [
            // {
            //     id: "colors",
            //     title: "Perfil",
            //     type: "item",
            //     icon: "person",
            //     url: "/ui/colors"
            // },
            {
                id: "logout",
                title: "Deslogar",
                type: "item",
                icon: "sign-out-alt",
                function: () => {
                    localStorage.clear();
                },
                url: "/auth/login"
            }
        ]
    }
];
