import {
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fuseAnimations } from "../../animations";

import { FuseNavigationService } from "../navigation/navigation.service";
import { FuseSidebarService } from "../sidebar/sidebar.service";
import { FuseConfigService } from "../../services/config.service";
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { NestedTreeControl, FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

interface FoodNode {
    name: string;
    children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
    {
        name: 'Data de Entrega',
        children: [
            { name: 'Próximos 7 dias' },
            { name: 'Amanhã' },
            { name: 'Hoje' },
            { name: 'Atrasado' },
        ]
    }, {
        name: 'Data de faturamento',
        children: [
            { name: 'Hoje' },
            { name: 'Ontem' },
            { name: 'Há uma semana' },
            { name: 'Mês atual' },
            { name: 'Há um mês' },
        ]
    }, {
        name: 'Data do pedido',
        children: [
            { name: 'Hoje' },
            { name: 'Ontem' },
            { name: 'Há uma semana' },
            { name: 'Mês atual' },
            { name: 'Há um mês' },
        ]
    },{
        name: 'Data de autorização',
        children: [
            { name: 'Hoje' },
            { name: 'Ontem' },
            { name: 'Há uma semana' },
            { name: 'Mês atual' },
            { name: 'Há um mês' },
        ]
    },
    {
        name: 'Situação do pedido',
        children: [
            { name: 'Incompleto' }
        ]
    },
    {
        name: 'Situação do erro',
        children: [
            { name: 'Evidenciar o erro' }
        ]
    }, {
        name: 'Campo buscado',
        children: [
            { name: 'Numero do pedido ' },
            { name: 'ID do SDK' },
            { name: 'ID da transação' },
            { name: 'ID da transação (TID)' }
        ]
    },
    {
        name: 'Status',
        children: [
            { name: 'Cancelado' },
            { name: 'Faturado' },
            { name: 'Pagamento pendente' }
           
        ]
    },
    

];

interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: "fuse-theme-options",
    templateUrl: "./theme-options.component.html",
    styleUrls: ["./theme-options.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations

})

export class FuseThemeOptionsComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    form: FormGroup;
    private _transformer = (node: FoodNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
        };
    }

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    @HostBinding("class.bar-closed")
    barClosed: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FormBuilder} _formBuilder
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Renderer2} _renderer
     */


    constructor(
        @Inject(DOCUMENT) private document: any,
        private _formBuilder: FormBuilder,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _renderer: Renderer2,

    ) {
        // Set the defaults
        this.barClosed = true;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.dataSource.data = TREE_DATA;
    }




    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Build the config form
        // noinspection TypeScriptValidateTypes
        this.form = this._formBuilder.group({
            colorTheme: new FormControl(),
            customScrollbars: new FormControl(),
            layout: this._formBuilder.group({
                style: new FormControl(),
                width: new FormControl(),
                navbar: this._formBuilder.group({
                    primaryBackground: new FormControl(),
                    secondaryBackground: new FormControl(),
                    folded: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl(),
                    variant: new FormControl()
                }),
                toolbar: this._formBuilder.group({
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl()
                }),
                footer: this._formBuilder.group({
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl()
                }),
                sidepanel: this._formBuilder.group({
                    hidden: new FormControl(),
                    position: new FormControl()
                })
            })
        });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                // Update the stored config
                this.fuseConfig = config;

                // Set the config form values without emitting an event
                // so that we don't end up with an infinite loop
                this.form.setValue(config, { emitEvent: false });
            });

        // Subscribe to the specific form value changes (layout.style)
        this.form
            .get("layout.style")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                // Reset the form values based on the
                // selected layout style
                this._resetFormValues(value);
            });

        // Subscribe to the form value changes
        this.form.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                // Update the config
                this._fuseConfigService.config = config;
            });

        // // Add customize nav item that opens the bar programmatically
        // const customFunctionNavItem = {
        //     id      : 'custom-function',
        //     title   : 'Custom Function',
        //     type    : 'group',
        //     icon    : 'settings',
        //     children: [
        //         {
        //             id      : 'customize',
        //             title   : 'Customize',
        //             type    : 'item',
        //             icon    : 'settings',
        //             function: () => {
        //                 this.toggleSidebarOpen('themeOptionsPanel');
        //             }
        //         }
        //     ]
        // };

        // this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Remove the custom function menu
        this._fuseNavigationService.removeNavigationItem("custom-function");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Reset the form values based on the
     * selected layout style
     *
     * @param value
     * @private
     */
    private _resetFormValues(value): void {
        switch (value) {
            // Vertical Layout #1
            case "vertical-layout-1": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "fuse-navy-700",
                            secondaryBackground: "fuse-navy-900",
                            folded: false,
                            hidden: false,
                            position: "left",
                            variant: "vertical-style-1"
                        },
                        toolbar: {
                            background: "fuse-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "below-static"
                        },
                        footer: {
                            background: "fuse-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "below-static"
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }

            // Vertical Layout #2
            case "vertical-layout-2": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "fuse-navy-700",
                            secondaryBackground: "fuse-navy-900",
                            folded: false,
                            hidden: false,
                            position: "left",
                            variant: "vertical-style-1"
                        },
                        toolbar: {
                            background: "fuse-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "below"
                        },
                        footer: {
                            background: "fuse-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "below"
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }

            // Vertical Layout #3
            case "vertical-layout-3": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "fuse-navy-700",
                            secondaryBackground: "fuse-navy-900",
                            folded: false,
                            hidden: false,
                            position: "left",
                            layout: "vertical-style-1"
                        },
                        toolbar: {
                            background: "fuse-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "above-static"
                        },
                        footer: {
                            background: "fuse-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "above-static"
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }

            // Vertical Layout #4
            case "vertical-layout-4": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "fuse-navy-700",
                            secondaryBackground: "fuse-navy-900",
                            folded: false,
                            hidden: false,
                            position: "left",
                            layout: "vertical-style-1"
                        },
                        toolbar: {
                            background: "fuse-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "above-static"
                        },
                        footer: {
                            background: "fuse-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "above-static"
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }

            // Vertical Layout #4
            case "vertical-layout-5": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "fuse-navy-700",
                            secondaryBackground: "fuse-navy-900",
                            folded: false,
                            hidden: false,
                            position: "left",
                            layout: "vertical-style-1"
                        },
                        toolbar: {
                            background: "fuse-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "above-static"
                        },
                        footer: {
                            background: "fuse-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "above-static"
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }

            // Horizontal Layout #1
            case "horizontal-layout-1": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "fuse-navy-700",
                            secondaryBackground: "fuse-navy-900",
                            folded: false,
                            hidden: false,
                            position: "top",
                            variant: "vertical-style-1"
                        },
                        toolbar: {
                            background: "fuse-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "above"
                        },
                        footer: {
                            background: "fuse-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "above-fixed"
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }
        }

    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
